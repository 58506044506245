<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import payConditionHeader from "@/components/payCondition-header";
  import {
    getPayCord,
    refreshPayCord
  } from "@/api/pay/statistics.js"
  /**
   * 收费统计
   */
  export default {
    components: {
      Layout,
      PageHeader,
      payConditionHeader



    },
    data() {
      return {
        title: "收费项目统计 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "收费项目统计",
            active: true
          }
        ],
        tableList: [],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          xmdm: "",
          nf: new Date().getFullYear(),
          ssdm: "",
          kssj: "",
          jssj: "",
        }

      };
    },
    methods: {
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      getList() {
        getPayCord(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },

      // 刷新统计
      refresh() {
        refreshPayCord(this.pageData).then(res => {
          console.log(res)
        })
      }
    },
    mounted() {
      this.getList()
    },
    computed: {
      tjsj() {
        return function(sj) {
          if (sj) {
            return sj.split("T").join(" ")
          }
        }
      }
    }

  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3  check-table-top">
              <div class="flexList" style="flex: 1;">
                <payConditionHeader :pageData="pageData"></payConditionHeader>
                <button type="button" class="btn btn-info h30 flexList mr-2 w-sm " @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button @click="refresh">获取最新数据统计</b-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border ">
              <table class="table light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th> 收费项目 </th>
                    <th>总记录</th>
                    <th>支付成功</th>
                    <th>未支付 </th>
                    <th>退费 </th>
                    <th>退费金额 </th>
                    <th>支付宝退费</th>
                    <th>微信退费</th>
                    <th>其他退费</th>
                    <th>收入总金额</th>
                    <th>支付宝收费</th>
                    <th>微信收费</th>
                    <th>其他收费</th>
                    <th>统计时间</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{obj.xmmc}} </td>
                    <td>{{obj.zjls}}</td>
                    <td>{{obj.zfcgs}}</td>
                    <td>{{obj.wzfs}}</td>
                    <td class="text-info">{{obj.tfs}}</td>
                    <td>{{obj.tfje/100}}</td>
                    <td>{{obj.zfbtfje/100}}</td>
                    <td>{{obj.wxtfje/100}}</td>
                    <td>{{obj.qttfje/100}}</td>
                    <td>{{obj.zsrje/100}}</td>
                    <td>{{obj.zfbsfje/100}}</td>
                    <td>{{obj.wxsfje/100}}</td>
                    <td>{{obj.qtsfje/100}}</td>
                    <td>{{tjsj(obj.tjsj)}}</td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
