// 支付统计
import {
  getRequest,
} from "@/libs/axios";
// 分页查询
export const getPayCord = params => {
  return getRequest("/yethan/pay/stats/listPage", params);
};
// 刷新统计结果
export const  refreshPayCord= params => {
  return getRequest("/yethan/pay/stats/doStats", params);
};